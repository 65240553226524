import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text,
    Button,
    useToast
  } from "@chakra-ui/react";
  import { isBrowser, isMobile} from "react-device-detect";
  import { SettingsIcon, ArrowDownIcon } from '@chakra-ui/icons';

  import Axios from "axios";
  import { ethers } from "ethers";
  import { usePAN } from "../hooks/usePAN";
  import { useEffect, useState, useContext } from "react";

  import LiquidityButton from "./LiquidityButton";
  import SwapTokenSelect2 from "./SwapTokenSelect2";
  import TokenSelect3 from "./TokenSelect3";

  import { AppContext } from "../context";
  import { useSwap } from "../hooks/useSwap";

  import coinList from "../coinList.json";
  import { logoMapping } from '../logoMapping';

  export default function RemoveLiquidModal({pair, isOpen, onClose}) {
    const [amountLP, setAmountLP] = useState("");
    const [amount1,  setAmount1]  = useState(0.0);
    const [amount2,  setAmount2]  = useState(0.0);
    const [allowedLP, setAllowedLP] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const {
      web3Provider,
      walletAddress,
      uniswapRouterAddress,
      swapCoin1,
      setSwapCoin1,
      swapCoin2,
      setSwapCoin2,
    } = useContext(AppContext);

    const { calLiquidity, calLPShare, exactInSwap, addLiquidity, removeLiquidity } = useSwap();
    const { checkAllowance, approveToken, getBalance } = usePAN();

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const toast = useToast();

    const handleTxCPLT = (desc, text) => {
      toast({
        title: desc + " is completed !",
        description: "Block Number: " + text,
        status: "success",
        duration: 10000,
        isClosable: true,
        containerStyle: {
          width: (isMobile) ? "96vw" : "" ,
          fontSize: (isMobile) ? "14px" : "" ,
        },
      });
    };

    const checkStatus = async (amountLP) => {
      if (web3Provider) {
        try {
          var resultLP = await checkAllowance(pair.liquidityToken_addr, amountLP, uniswapRouterAddress);
          
          setAllowedLP( resultLP ); 

          console.log(allowedLP);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const setTokenAmount = async (mode) => {
      
      var amountLP;

      var myTokenLP = await getBalance(pair.liquidityToken_addr, walletAddress);
      myTokenLP = Number(myTokenLP.toString()).toFixed(8);

      if (mode===0)      {
        amountLP = Number(Number(myTokenLP)*1/4).toFixed(8);
      }
      else if (mode===1) {
        amountLP = Number(myTokenLP*2/4).toFixed(8);
      }
      else if (mode===2) {
        amountLP = Number(myTokenLP*3/4).toFixed(8);
      }
      else if (mode===3) {
        amountLP = Number(myTokenLP).toFixed(8);
      }

      var liquid = await calLPShare(pair, amountLP);
      console.log(liquid);

      setAmountLP(amountLP);
      setAmount1(liquid.amountA);
      setAmount2(liquid.amountB);
      checkStatus(amountLP);
    }

    coinList.forEach((coin) => {
      if (logoMapping[coin.name]) {
        coin.icon = logoMapping[coin.name];
      }
    });

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          background="green.50"
          border="0.2rem"
          borderStyle="solid"
          borderColor="green.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            <Flex justifyContent="space-between" alignItems="center">
                Remove Liquidity
                <Flex justifyContent="space-between" alignItems="center">
                  <Button fontSize="13px" w="41px" h="24px" color="blue.700" fontWeight="bold" borderRadius="0.3rem" border="1px" mr="0.4rem" onClick={ async() => {setTokenAmount(0);} }>25%</Button>
                  <Button fontSize="13px" w="41px" h="24px" color="blue.700" fontWeight="bold" borderRadius="0.3rem" border="1px" mr="0.4rem" onClick={ async() => {setTokenAmount(1);} }>50%</Button>
                  <Button fontSize="13px" w="41px" h="24px" color="blue.700" fontWeight="bold" borderRadius="0.3rem" border="1px" mr="0.4rem" onClick={ async() => {setTokenAmount(2);} }>75%</Button>
                  <Button fontSize="13px" w="41px" h="24px" color="blue.700" fontWeight="bold" borderRadius="0.3rem" border="1px" mr="2.5rem" onClick={ async() => {setTokenAmount(3);} }>MAX</Button>
                </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>

          <Box>
              <Box
              p="0rem 0.5rem 0.5rem 0.5rem"
              w="100%"
              bg="green.50"
              borderRadius="0 0 0rem 0rem">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  mb="0.25rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      Input:
                    </Text>
                  </Box>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="white"
                    p="0.18rem"
                    borderRadius="1.25rem"
                    pos="absolute"
                    top="7.7rem"
                    left="50%"
                    transform="translateX(-50%)"
                    w="2rem">
                    <ArrowDownIcon
                      bg="rgb(247, 248, 250)"
                      color="rgb(128,128,128)"
                      h="1.5rem" width="1.62rem"
                      borderRadius="1.25rem"
                    />
                  </Flex>
                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={amountLP}
                      style={{ boxShadow: "none" }}
                      onChange={ async (e)  => {const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmountLP(newAmount);
                                                if (newAmount!=="") { var liquid = await calLPShare(pair, newAmount); setAmount1(liquid.amountA); setAmount2(liquid.amountB); }
                                                checkStatus(newAmount);
                                               }}
                    />
                  </Box>
                  <Box ml="0.5rem">
                  <TokenSelect3 image={pair.icon1} image2={pair.icon2} value={"LP"} />
                  </Box>
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      Output:
                    </Text>
                  </Box>
                  <Box width="86%">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="black"
                      borderRadius="0.25rem"
                      color={ amountLP==="" ? "gray" : "black"}
                    >{amount1}</Text>
                  </Box>
                  <Box ml="0.5rem">
                  <SwapTokenSelect2 openTokenModal={""} setTokenNumber={""} tokenNumber={1} value={pair.symbolA} image={pair.icon1}/>
                  </Box>
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  mt="0.25rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  >
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      Output:
                    </Text>
                  </Box>
                  <Box width="86%">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="black"
                      borderRadius="0.25rem"
                      color={ amountLP==="" ? "gray" : "black"}
                    >{amount2}</Text>
                  </Box>
                  <Box ml="0.5rem">
                  <SwapTokenSelect2 openTokenModal={""} setTokenNumber={""} tokenNumber={2} value={pair.symbolB} image={pair.icon2}/>
                  </Box>
                </Flex>
              </Box>              

              { (allowedLP===false) && <LiquidityButton onClick={ async () => { var tx = await approveToken(pair.liquidityToken_addr, uniswapRouterAddress, amountLP);
                                                                                if (typeof tx !== 'undefined') {
                                                                                  setIsLoading(true);
                                                                                  var receipt = await tx.wait(); //await tx
                                                                                  await delay(10000);
                                                                                  if (receipt.status === 0) { console.log("tx failed"); }
                                                                                  if (receipt.status === 1) { handleTxCPLT("ApproveToken", receipt.blockNumber); }
                                                                                  setIsLoading(false);
                                                                                  checkStatus(amountLP); }
                                                                              }}
                                                        isLoading={isLoading} isDisable={false} text={"Approve " + pair.name} allowed={false} /> }

              { (allowedLP===true)  && <LiquidityButton onClick={ async () => { var tx = await removeLiquidity(pair, amountLP, amount1, amount2);
                                                                                if (typeof tx !== 'undefined') {
                                                                                  setIsLoading(true);
                                                                                  var receipt = await tx.wait(); //await tx
                                                                                  await delay(10000);
                                                                                  if (receipt.status === 0) { console.log("tx failed"); }
                                                                                  if (receipt.status === 1) { handleTxCPLT("RemoveLiquidity", receipt.blockNumber); }
                                                                                  setIsLoading(false); }
                                                                              }}
                                                        isLoading={isLoading} isDisable={false} text="Remove" allowed={true} /> }

            </Box>


          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="green.50"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  