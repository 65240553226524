export const logoMapping = {
    "Cronos"          : require("./assets/CRO.png"),
    "Wrapped Cronos"  : require("./assets/WCRO.png"),
    "principle Cronos": require("./assets/ptCRO.png"),
    "yield Cronos"    : require("./assets/ytCRO.png"),
    "PAN Token"       : require("./assets/creampan_color2_2.png"),
    "Bonded Cronos"   : require("./assets/bCRO_logo.png"),
    "Liquid Cronos"   : require("./assets/lcro_logo.png"),
    "Tether USD"      : require("./assets/tether-usdt-logo.png"),
    "USD Coin"      : require("./assets/usd-coin-usdc-logo.png")
  };
  