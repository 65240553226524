import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, Spacer, Switch, Circle, Image } from "@chakra-ui/react";
import { SettingsIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";  
import { isIOS, isAndroid } from "react-device-detect";
import SwitchTab from "./components/SwitchTab";
import FlexButton from "./components/FlexButton";
import { useWeb3 } from "./hooks/useWeb3";
import { useSwap } from "./hooks/useSwap";
import { usePAN } from "./hooks/usePAN";
import { useIcon } from "./hooks/useIcon";


import { AppContext } from "./context";

import pairList from "./pairList.json";

import logo_cronos from "./assets/cronos_light_bg.png";

function PAN() {
  const {
    price_ptCRO,
    price_ytCRO,
    price_PAN,
    stakeingList,
    setStakingList,
    poolList,
    setPoolList,
    web3Provider,
    walletAddress,
    selectedTab,
    ptCRO_WCRO_address,
    ytCRO_WCRO_address,
    PAN_WCRO_address,
    ptCRO_ADDRESS,
    ytCRO_ADDRESS,
    PAN_address,
    blockNumber
  } = useContext(AppContext);

  const { getPTCROPrice, getYTCROPrice, getPANPrice, getStakeAmount, getTotalStake, getRewards, getAPRFromServer, getTotalSupply, getBalance } = usePAN();
  const { handleExplorer } = useWeb3();
  const { calLPShare } = useSwap();
  const { CircleIcon } = useIcon();

  const tabs = ["Staking", "Pool"];

  const updateContractBalance = async () => {
    var LPToTokenAmount;

    for (let i=0; i < poolList.length; i++)
    {
      //console.log(poolList[i].contractAddress);
      poolList[i].totalLPSupply = await getTotalSupply(poolList[i].contractAddress);
      if (poolList[i].tokenName === "PAN") {
        poolList[i].contractBalance = await getBalance(PAN_address, poolList[i].contractAddress);

        LPToTokenAmount = await calLPShare(pairList[0], "1"); //PAN-WCRO pool
        poolList[i].LPToTokenAmount = LPToTokenAmount.amountA;    //LP to PAN amount
      }
      else if (poolList[i].tokenName === "ptCRO") {
        poolList[i].contractBalance = await getBalance(ptCRO_ADDRESS, poolList[i].contractAddress);

        LPToTokenAmount = await calLPShare(pairList[1], "1"); //ptCRO-WCRO pool
        poolList[i].LPToTokenAmount = LPToTokenAmount.amountA;    //LP to ptCRO amount
      }
      else if (poolList[i].tokenName === "ytCRO") {
        poolList[i].contractBalance = await getBalance(ytCRO_ADDRESS, poolList[i].contractAddress);

        LPToTokenAmount = await calLPShare(pairList[2], "1"); //ytCRO-WCRO pool
        poolList[i].LPToTokenAmount = LPToTokenAmount.amountA;    //LP to ytCRO amount
      }
    }
    //console.log(poolList);
  }

  const updateStakingList = (pid, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price) => {
    var apr;

    setStakingList(prevStakingList => {
      return prevStakingList.map(item => {
        if (Number(item.pid) === pid) {
          if      (item.tokenName === "PAN") {
            apr = Number (newAPR).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: PAN_Price };
          }
          else if (item.tokenName === "ptCRO") {
            apr = Number(Number (newAPR) * Number(PAN_Price) / Number(ptCRO_Price)).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: ptCRO_Price };
          }
          else if (item.tokenName === "ytCRO") {
            apr = Number(Number (newAPR) * Number(PAN_Price) / Number(ytCRO_Price)).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: ytCRO_Price };
          }
          else {
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: "0", totalStaked: newTotalStaked };
          }
        }
          return item;
      });
    });
  }

  const updatePoolList = (pid, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price ) => {
    var apr;
  
    setPoolList( prevPoolList => {
      return prevPoolList.map(item => {

        if (Number(item.pid) === pid) {
          if      (item.tokenName === "PAN") {
            apr = Number(Number (newAPR) * ( Number(item.contractBalance) / Number(item.totalLPSupply) * ( 1 / (2*Number(item.LPToTokenAmount)) ) )).toFixed(2);
            //console.log(newAPR, item.contractBalance, item.totalLPSupply, apr);
            //console.log(item.LPToTokenAmount, "PAN");
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: (2*Number(PAN_Price)*Number(item.LPToTokenAmount)) };
          }
          else if (item.tokenName === "ptCRO") {
            apr = Number(Number (newAPR) * ( Number(item.contractBalance) / Number(item.totalLPSupply) ) * Number(PAN_Price) / (2*Number(ptCRO_Price)*Number(item.LPToTokenAmount))).toFixed(2);
            //console.log(newAPR, PAN_Price, Number(ptCRO_Price), apr);
            //console.log(item.LPToTokenAmount, "ptCRO");
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: (2*Number(ptCRO_Price)*Number(item.LPToTokenAmount)) };
          }
          else if (item.tokenName === "ytCRO") {
            apr = Number(Number (newAPR) * 2 * ( Number(item.contractBalance) / Number(item.totalLPSupply) ) * Number(PAN_Price) / (2*Number(ytCRO_Price)*Number(item.LPToTokenAmount))).toFixed(2);
            //console.log(newAPR, PAN_Price, Number(ytCRO_Price), apr);
            //console.log(item.LPToTokenAmount, "ytCRO");
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: (2*Number(ytCRO_Price)*Number(item.LPToTokenAmount)) };
          }
          else {
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: newAPR, totalStaked: newTotalStaked };
          }

        }
          return item;
      });
    });
  }

  //regular update states
  useEffect(() => {
    let intervalId;

    const updateStatus = async () => {
      var res;
      var ptCRO_Price;
      var ytCRO_Price;
      var PAN_Price;
      var newStakedAmount;
      var newReward;
      var newAPR;
      var newTotalStaked;

      if (web3Provider) {
        try {
          //console.log("PAN update", Date.now()/1000);
          res         = await getAPRFromServer();
          ptCRO_Price = price_ptCRO;
          ytCRO_Price = price_ytCRO;
          PAN_Price   = price_PAN;
          //console.log("PAN update", Date.now()/1000);
          await updateContractBalance();
          //console.log(PAN_Price, ptCRO_Price, ytCRO_Price);
          for (let i=0; i < 6; i++)
          {
            newStakedAmount = await getStakeAmount(i);
            newReward       = await getRewards(i);
            //console.log(i, newStakedAmount, newReward, newAPR, newTotalStaked);
            //newReward       = Number(Number(newReward)*51/100).toFixed(1); //Only 51% coin is rewards
            //console.log(i, newStakedAmount, newReward, newAPR, newTotalStaked);
            newAPR          = res[i].apr;
            newTotalStaked  = await getTotalStake(i);
            //console.log(i, newStakedAmount, newReward, newAPR, newTotalStaked);
            updateStakingList(i, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price);
            updatePoolList   (i, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price);
            //console.log("PAN update", i , Date.now()/1000);
          }
          //console.log(stakeingList);
        } catch (error) {
          console.error(error);
        }
      }
    };
  
    if (web3Provider) {
      updateStatus();
      intervalId = setInterval(updateStatus, 20000);
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider, walletAddress]);
  ///////////////////////

      return (
        <Box
            align="center"                                 
            display="flex"                                 
            flexDirection="column"                         
            justifyContent="space-between"                 
            height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                               : "92.3vh"}                                              
            css={{                                         
              overflowY: "scroll",                         
              "&::-webkit-scrollbar": {                    
                width: (isMobile) ? '0px' : '16px',                             
              },                                           
              "&::-webkit-scrollbar-track": {              
                backgroundColor: "transparent",            
              },                                           
              "&::-webkit-scrollbar-thumb": {              
                backgroundColor: "rgba(205, 102, 0, 0.5)", 
                borderRadius: "20px",                      
                border: "4px solid transparent",           
                backgroundClip: "content-box",             
              },                                           
              "&::-webkit-scrollbar-thumb:hover": {        
                backgroundColor: "rgba(205, 102, 0, 0.8)", 
              },                                           
            }} 
          >
          <Flex justifyContent="center" alignItems="center">
          <Box position="relative" top="60px" borderRadius="2rem" bg="orange.200" border="2px" borderColor="orange.200" p="0.4rem">
          <SwitchTab tabs={tabs} onChange={()=>{}} />
          </Box>
          </Flex>

          <Box 
            w="60rem"
            mx="auto"
            position="relative"
            top="60px"
            boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
            borderRadius="1.25rem"
          >
            <Box mt="0rem">
            { (selectedTab===0) && stakeingList.map((val, index) => (
              <FlexButton key={val.name} name={val.name} icon={val.logo} icon2={val.logo} isFirst={(index===0) ? true : false} isLast={(index===(stakeingList.length-1)) ? true : false} isPool={false} pid={val.pid}
                          myStake={val.myStake} myReward={val.myReward} APR={val.APR} totalStaked={val.totalStaked} contractAddress={val.contractAddress} stakeTokenPrice={val.price} />
            ))}
            { (selectedTab===1) &&     poolList.map((val, index) => (
              <FlexButton key={val.name} name={val.name} icon={val.logo} icon2={val.logo2} isFirst={(index===0) ? true : false} isLast={(index===(poolList.length-1))     ? true : false} isPool={true} pid={val.pid}
                          myStake={val.myStake} myReward={val.myReward} APR={val.APR} totalStaked={val.totalStaked} contractAddress={val.contractAddress} stakeTokenPrice={val.price} />
            ))}
            </Box>
          </Box>
        
        <Flex
          alignItems="center"
          justifyContent="space-evenly">
          <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
          </Text>
        </Flex>

        <Flex bottom="0px" justify="space-between" alignItems="center" width="99vw" mb={(isAndroid) ? "0.7rem" : "0.2rem"}>
          
          <Flex mr="16px" w="90px">
          </Flex>

          <Flex alignItems="center">
            <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
            <Box width="85px" height="30px">
              <Image src={logo_cronos} fit="contain"/>
            </Box>
          </Flex>

          <Flex alignItems="center" w={(web3Provider) ? "" : "90px"} >
            {web3Provider && (
            <Flex alignItems="center">
            <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
            <CircleIcon boxSize={3} color="rgb(55, 160, 105)" mr="0px"/>
            </Flex>
            )}
            {!web3Provider && (
            <Flex alignItems="center">
            <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
            <CircleIcon boxSize={3} color="transparent" mr="0px"/>
            </Flex>
            )}
          </Flex>
        </Flex>

        </Box>
      );
  
}

export default PAN;
