import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { AppContext } from "../context";

export function useFactory() {
  const {
    amount,
    cronos_nodeUrl,
    walletAddress,
    Factory_ADDRESS,
    Factory_ABI,
    ptCRO_ADDRESS,
    ptCRO_ABI,
    ytCRO_ADDRESS,
    ytCRO_ABI,
    PAN_address,
    PAN_ABI,
    WCRO_address,
    web3Provider,
    web3JsonRpcProvider,
    setIsLoading,
    selectedCoin,
    setBurnFee,
    setUtilization,
    setPanHodlLevel,
    setHoldDiscount,
    setBalanceptCRO,
    setBalanceytCRO,
    setBalancePAN,
    setPendingRewards,
    setFlexibleCRO,
  } = useContext(AppContext);

  async function getAccountBalance() {
    //const signer = web3Provider.getSigner();
    const currentAddress = await web3Provider.getSigner().getAddress();
    //var Balance = await web3Provider.getBalance(signer.getAddress());

    const ptCRO_contract = new ethers.Contract(ptCRO_ADDRESS, ptCRO_ABI, web3Provider);
    var BalanceptCRO = await ptCRO_contract.balanceOf(currentAddress);
  
    const ytCRO_contract = new ethers.Contract(ytCRO_ADDRESS, ytCRO_ABI, web3Provider);
    var BalanceytCRO = await ytCRO_contract.balanceOf(currentAddress);
    var pendingRewards = await ytCRO_contract.getPendingRewards(currentAddress);

    const PAN_contract = new ethers.Contract(PAN_address, PAN_ABI, web3Provider);
    var BalanceytPAN = await PAN_contract.balanceOf(currentAddress);

    const WCRO_contract = new ethers.Contract(WCRO_address, ptCRO_ABI, web3Provider); //use ptCRO_ABI for ERC20 balanceOf query.
    var factoryCRO = await await WCRO_contract.balanceOf(Factory_ADDRESS);
    

    //setBalanceCRO(Number(ethers.utils.formatEther(Balance)).toFixed(3));
    setBalanceptCRO(Number(ethers.utils.formatEther(BalanceptCRO)).toFixed(3));
    setBalanceytCRO(Number(ethers.utils.formatEther(BalanceytCRO)).toFixed(3));
    setBalancePAN(Number(ethers.utils.formatEther(BalanceytPAN)).toFixed(3));
    setPendingRewards(Number(ethers.utils.formatEther(pendingRewards)).toFixed(3));
    setFlexibleCRO(Number(ethers.utils.formatEther(factoryCRO)).toFixed(3));
  }

  async function getHodlLVLDiscnt() {
    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider);
    const currentAddress = await web3Provider.getSigner().getAddress();
    
    if (currentAddress !== "") {
      var holdDiscount = await Factory_contract.getHoldDiscount(currentAddress);
      //console.log(Number(holdDiscount));
      var hodlLevel = 0;

      if      (Number(holdDiscount) === 100)
        hodlLevel = 0;
      else if (Number(holdDiscount) === 90)
        hodlLevel = 1;
      else if (Number(holdDiscount) === 75)
        hodlLevel = 2;
      else if (Number(holdDiscount) === 55)
        hodlLevel = 3;

      setHoldDiscount( (100-Number(holdDiscount)) );
      setPanHodlLevel(Number(hodlLevel));
    }
  }

  async function getUtilization() {

    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider);
    var delegatioRatio = await Factory_contract.getDelegationRatio();
    var targetRatio    = await Factory_contract.getTargetRatio();

    delegatioRatio = Number(Number(delegatioRatio) / 1e4).toFixed(0);
    targetRatio    = Number(Number(targetRatio)    / 1e4).toFixed(0);

    var reserveRatio = (Number(targetRatio)<=0)      ? 100 : 
                       (Number(delegatioRatio)>100)  ? 100 : 100*(100-delegatioRatio) / (100-targetRatio);
    var util         = (Number(reserveRatio)>100)    ?   0 : Number(100 - reserveRatio).toFixed(0);
    setUtilization(Number(util));
    //console.log(Number(delegatioRatio), Number(targetRatio), reserveRatio);
  }

  async function getBurnFee() {

    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider);
    var BurnFee = await Factory_contract.getCurrentFee();
    BurnFee = Number( Number(BurnFee) / 1e6 );

    setBurnFee(Number(BurnFee));
    //console.log(BurnFee);
  }

  async function mint_ptCRO() {
    setIsLoading(true);

    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider.getSigner());
    var tx;

    const gasLimit = 300000;

    try {
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var mintAmount = ethers.utils.parseEther(amount);
      }
      //console.log(walletAddress, mintAmount);

      if      (selectedCoin.symbol === "CRO") {
        tx = await Factory_contract.mint(walletAddress, {value: mintAmount, gasLimit: gasLimit});
      }
      else if (selectedCoin.symbol === "WCRO") {
        tx = await Factory_contract.mintFromWCRO(walletAddress, mintAmount, {gasLimit});
      }

      //console.log(tx);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function claimRewards() {
    setIsLoading(true);

    const ytCRO_contract = new ethers.Contract(ytCRO_ADDRESS, ytCRO_ABI, web3Provider.getSigner());
    var tx;

    try {

      if      (selectedCoin.symbol === "CRO") {
        const claimFunc = ytCRO_contract.functions['claim()'];
        tx = await claimFunc.call();
      }
      else if (selectedCoin.symbol === "WCRO") {
        const claimToWCROFunc = ytCRO_contract.functions['claimToWCRO()'];
        tx = await claimToWCROFunc.call();
      }

      //console.log(tx);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function burn_ptCRO() {
    setIsLoading(true);

    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, web3Provider.getSigner());
    var tx;

    const gasLimit = 300000;

    try {
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var burnAmount = ethers.utils.parseEther(amount);
      }
      //console.log(walletAddress, burnAmount);

      if      (selectedCoin.symbol === "CRO") {
        tx = await Factory_contract.burn(burnAmount, {gasLimit});
      }
      else if (selectedCoin.symbol === "WCRO") {
        tx = await Factory_contract.burnToWCRO(burnAmount, {gasLimit});
      }

      //console.log(tx);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }


  return {
    getAccountBalance,
    getUtilization,
    getBurnFee,
    getHodlLVLDiscnt,
    mint_ptCRO,
    burn_ptCRO,
    claimRewards
  };
}
