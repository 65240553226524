import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text,
    Button,
    useToast
  } from "@chakra-ui/react";
  import { SettingsIcon, SmallAddIcon, ArrowDownIcon } from '@chakra-ui/icons';
  import { isBrowser, isMobile} from "react-device-detect";
//  import { ChainId, Fetcher, Token, Route, Trade, TokenAmount, TradeType, Percent } from '@uniswap/sdk';
  import { ChainId, Fetcher, Token, Route, Trade, TokenAmount, TradeType, Percent } from 'vvs-sdk';
  import { useEffect, useState, useContext } from "react";


  import Axios from "axios";
  import { ethers } from "ethers";
  import { usePAN } from "../hooks/usePAN";
  
  import LiquidityButton from "./LiquidityButton";
  import SwapTokenSelect2 from "./SwapTokenSelect2";

  import { AppContext } from "../context";
  import { useSwap } from "../hooks/useSwap";

  import coinList from "../coinList.json";
  import { logoMapping } from '../logoMapping';

  export default function AddLiquidModal({pair, isOpen, onClose}) {
    const [amount1, setAmount1] = useState("");
    const [amount2, setAmount2] = useState("");
    const [tokenSufficient, setTokenSufficient] = useState(true);
    const [allowedA, setAllowedA] = useState(true);
    const [allowedB, setAllowedB] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const {
      web3Provider,
      walletAddress,
      uniswapRouterAddress,
      swapCoin1,
      setSwapCoin1,
      swapCoin2,
      setSwapCoin2,
    } = useContext(AppContext);
    
    const { calLiquidity, exactInSwap, addLiquidity, removeLiquidity } = useSwap();
    const { checkAllowance, approveToken, getBalance } = usePAN();
    const { BigNumber } = require('ethers');

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    const toast = useToast();

    const handleTxCPLT = (desc, text) => {
      toast({
        title: desc + " is completed !",
        description: "Block Number: " + text,
        status: "success",
        duration: 10000,
        isClosable: true,
        containerStyle: {
          width: (isMobile) ? "96vw" : "" ,
          fontSize: (isMobile) ? "14px" : "" ,
        },
      });
    };

    const checkStatus = async (amountA, amountB) => {
      if (web3Provider) {
        try {
          var resultA = await checkAllowance(pair.tokenA_addr, amountA, uniswapRouterAddress);
          var resultB = await checkAllowance(pair.tokenB_addr, amountB, uniswapRouterAddress);
          
          setAllowedA( resultA ); 
          setAllowedB( resultB ); 

          console.log(allowedA, allowedB);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const checkTokenAmount = async (amountA, amountB) => {
      if (web3Provider) {
        try {
          var myTokenA = await getBalance(pair.tokenA_addr, walletAddress);
          var myTokenB = await getBalance(pair.tokenB_addr, walletAddress);
          myTokenA = Number(myTokenA.toString()).toFixed(3);
          myTokenB = Number(myTokenB.toString()).toFixed(3);

          if ((Number(myTokenA)>=Number(amountA)) && (Number(myTokenB)>=Number(amountB)))
            { setTokenSufficient(true); }
          else
            { setTokenSufficient(false); }

            console.log(tokenSufficient);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const setTokenA_MAX = async () => {
      const tokenA = new Token(ChainId.TESTNET, pair.tokenA_addr,pair.tokenA_decimals);
      const tokenB = new Token(ChainId.TESTNET, pair.tokenB_addr,pair.tokenB_decimals);

      const tokenPair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const reserve0 = tokenPair.reserve0.toFixed(pair.tokenA_decimals);
      const reserve1 = tokenPair.reserve1.toFixed(pair.tokenB_decimals);
      const reserve0_BigN = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN = BigNumber.from(ethers.utils.parseEther(reserve1));

      var myTokenA = await getBalance(pair.tokenA_addr, walletAddress);
      myTokenA = Number(myTokenA.toString()).toFixed(8);

      var myTokenA_BigN = BigNumber.from(ethers.utils.parseEther(myTokenA));
      //console.log(myTokenA, myTokenA_BigN.toString());

      var amountBDesired;
      var amountB_ether;
      if (tokenPair.token0.address === pair.tokenA_addr) {
        amountBDesired = myTokenA_BigN.mul(reserve1_BigN).div(reserve0_BigN).toString();
        amountB_ether = Number(ethers.utils.formatEther(amountBDesired)).toFixed(8);
        //console.log(tokenPair.token0.address, pair.tokenA_addr, 1);
        //console.log(amountBDesired, amountB_ether);
      }
      else {
        amountBDesired = myTokenA_BigN.mul(reserve0_BigN).div(reserve1_BigN).toString();
        amountB_ether = Number(ethers.utils.formatEther(amountBDesired)).toFixed(8);
        //console.log(tokenPair.token0.address, pair.tokenA_addr, 2);
        //console.log(amountBDesired, amountB_ether);
      }

      setAmount1(myTokenA);
      setAmount2(amountB_ether);
      checkStatus(myTokenA, amountB_ether);
      checkTokenAmount(myTokenA, amountB_ether);
    }

    const setTokenB_MAX = async () => {
      const tokenA = new Token(ChainId.TESTNET, pair.tokenA_addr,pair.tokenA_decimals);
      const tokenB = new Token(ChainId.TESTNET, pair.tokenB_addr,pair.tokenB_decimals);

      const tokenPair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const reserve0 = tokenPair.reserve0.toFixed(pair.tokenA_decimals);
      const reserve1 = tokenPair.reserve1.toFixed(pair.tokenB_decimals);
      const reserve0_BigN = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN = BigNumber.from(ethers.utils.parseEther(reserve1));

      var myTokenB = await getBalance(pair.tokenB_addr, walletAddress);
      myTokenB = Number(myTokenB.toString()).toFixed(8);

      var myTokenB_BigN = BigNumber.from(ethers.utils.parseEther(myTokenB));
      //console.log(myTokenB, myTokenB_BigN.toString());

      var amountADesired;
      var amountA_ether;
      if (tokenPair.token0.address === pair.tokenB_addr) {
        amountADesired = myTokenB_BigN.mul(reserve1_BigN).div(reserve0_BigN).toString();
        amountA_ether = Number(ethers.utils.formatEther(amountADesired)).toFixed(8);
        //console.log(tokenPair.token0.address, pair.tokenA_addr, 1);
        //console.log(amountBDesired, amountB_ether);
      }
      else {
        amountADesired = myTokenB_BigN.mul(reserve0_BigN).div(reserve1_BigN).toString();
        amountA_ether = Number(ethers.utils.formatEther(amountADesired)).toFixed(8);
        //console.log(tokenPair.token0.address, pair.tokenA_addr, 2);
        //console.log(amountBDesired, amountB_ether);
      }

      setAmount1(amountA_ether);
      setAmount2(myTokenB);
      checkStatus(amountA_ether, myTokenB);
      checkTokenAmount(amountA_ether, myTokenB);
    }

    coinList.forEach((coin) => {
      if (logoMapping[coin.name]) {
        coin.icon = logoMapping[coin.name];
      }
    });

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          background="green.50"
          border="0.2rem"
          borderStyle="solid"
          borderColor="green.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            <Flex justifyContent="space-between" alignItems="center">
                Add Liquidity
                <Flex justifyContent="space-between" alignItems="center">
                  <Button fontSize="13px" w="89px" h="24px" color="blue.700" fontWeight="bold" borderRadius="0.3rem" border="1px" mr="0.8rem" onClick={setTokenA_MAX}>100% {pair.symbolA}</Button>
                  <Button fontSize="13px" w="89px" h="24px" color="blue.700" fontWeight="bold" borderRadius="0.3rem" border="1px" mr="2.5rem" onClick={setTokenB_MAX}>100% {pair.symbolB}</Button>
                </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>

          <Box>
              <Box
              p="0rem 0.5rem 0.5rem 0.5rem"
              w="100%"
              bg="green.50"
              borderRadius="0 0 0rem 0rem">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      Input:
                    </Text>
                  </Box>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    bg="white"
                    p="0.18rem"
                    borderRadius="1.25rem"
                    pos="absolute"
                    top="7.5rem"
                    left="50%"
                    transform="translateX(-50%)"
                    w="2rem">
                    <SmallAddIcon
                      bg="rgb(247, 248, 250)"
                      color="rgb(128,128,128)"
                      h="1.5rem" width="1.62rem"
                      borderRadius="1.25rem"
                    />
                  </Flex>
                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={amount1}
                      style={{ boxShadow: "none" }}
                      onChange={ async (e)  => {var   amountOUT; 
                                                const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount1(newAmount);
                                                if (newAmount!=="") { amountOUT = await calLiquidity(pair, newAmount, "A"); setAmount2(amountOUT); } else { setAmount2(""); }
                                                checkStatus(newAmount, amountOUT);
                                                checkTokenAmount(newAmount, amountOUT);
                                               }}
                    />
                  </Box>
                  <Box ml="0.5rem">
                  <SwapTokenSelect2 value={pair.symbolA} image={pair.icon1}/>
                  </Box>
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="1rem 1rem 1rem"
                  mt="0.25rem"
                  borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                  height="75px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  >
                  <Box>
                    <Text
                      width="70px"
                      color="black"
                      fontWeight="bold">
                      Input:
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={amount2}
                      style={{ boxShadow: "none" }}
                      onChange={ async (e)  => {var   amountOUT; 
                                                const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount2(newAmount);
                                                if (newAmount!=="") { amountOUT = await calLiquidity(pair, newAmount, "B"); setAmount1(amountOUT); } else { setAmount2(""); }
                                                checkStatus(amountOUT, newAmount);
                                                checkTokenAmount(amountOUT, newAmount);
                                               }}
                    />
                  </Box>
                  <Box ml="0.5rem">
                  <SwapTokenSelect2 value={pair.symbolB} image={pair.icon2}/>
                  </Box>
                </Flex>
              </Box>              
                
              { (tokenSufficient===false) &&                                         <LiquidityButton onClick={ async () => {} }
                                                                                                      isLoading={isLoading} isDisable={true} text="Insufficient Token" allowed={false} /> }

              { ((tokenSufficient===true)&&(allowedA===false)) &&                    <LiquidityButton onClick={ async () => { var tx = await approveToken(pair.tokenA_addr, uniswapRouterAddress, amount1); 
                                                                                                                              if (typeof tx !== 'undefined') {
                                                                                                                                setIsLoading(true);
                                                                                                                                var receipt = await tx.wait(); //await tx
                                                                                                                                await delay(10000);
                                                                                                                                if (receipt.status === 0) { console.log("tx failed"); }
                                                                                                                                if (receipt.status === 1) { handleTxCPLT("ApproveToken", receipt.blockNumber); }
                                                                                                                                setIsLoading(false);
                                                                                                                                checkStatus(amount1, amount2); }
                                                                                                                             }}
                                                                                                      isLoading={isLoading} isDisable={false} text={"Approve " + pair.symbolA} allowed={false} /> }

              { ((tokenSufficient===true)&&(allowedA===true)&&(allowedB===false)) && <LiquidityButton onClick={ async () => { var tx = await approveToken(pair.tokenB_addr, uniswapRouterAddress, amount2);
                                                                                                                              if (typeof tx !== 'undefined') {
                                                                                                                                setIsLoading(true);
                                                                                                                                var receipt = await tx.wait(); //await tx
                                                                                                                                await delay(10000);
                                                                                                                                if (receipt.status === 0) { console.log("tx failed"); }
                                                                                                                                if (receipt.status === 1) { handleTxCPLT("ApproveToken", receipt.blockNumber); }
                                                                                                                                setIsLoading(false);
                                                                                                                                checkStatus(amount1, amount2); }
                                                                                                                            }}
                                                                                                      isLoading={isLoading} isDisable={false} text={"Approve " + pair.symbolB} allowed={false} /> }

              { ((tokenSufficient===true)&&(allowedA===true)&&(allowedB===true))  && <LiquidityButton onClick={ async () => { var tx = await addLiquidity(pair, amount1, amount2);
                                                                                                                              if (typeof tx !== 'undefined') {
                                                                                                                                setIsLoading(true);
                                                                                                                                var receipt = await tx.wait(); //await tx
                                                                                                                                await delay(10000);
                                                                                                                                if (receipt.status === 0) { console.log("tx failed"); }
                                                                                                                                if (receipt.status === 1) { handleTxCPLT("AddLiquidity", receipt.blockNumber); }
                                                                                                                                setIsLoading(false); }
                                                                                                                            }}
                                                                                                      isLoading={isLoading} isDisable={false} text="Supply" allowed={true} /> }

            </Box>




          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="green.50"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  