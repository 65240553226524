import React, { createContext, useState } from "react";
import logo from "./assets/creampan.png";
import logo_color3_2 from "./assets/creampan_color3_2.png";
import logo_color2_2 from "./assets/creampan_color2_2.png";
import ptCRO_logo from "./assets/ptCRO.png";
import ytCRO_logo from "./assets/ytCRO.png";
import WCRO_logo from "./assets/WCRO.png";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const cro_apiUrl = "https://rest.mainnet.crypto.org/"; //MAINNET
  const cronos_nodeUrl = "https://nd-635-296-389.p2pify.com/dc6aa39aeaefa59c71e8b38c28fa9e24"; //MAINNET: https://evm.cronos.org/  https://rpc.vvs.finance/
  const chainId = "25"; //MAINNET
  const PAN_address         = "0xDa6c746E8a14EFE6E0A031F5d632bd2B0adC28Be"; //MAINNET
  const USDT_address        = "0x66e428c3f67a68878562e79A0234c1F83c208770"; //MAINNET
  const ptCRO_ADDRESS       = "0x51f2AB325A81b0Eb36B0814Ca2fA8A23CB63d731"; //MAINNET
  const ytCRO_ADDRESS       = "0x3D329Eb0eD1f460b4A52c9F70C20a1A5861f74E4"; //MAINNET
  const Factory_ADDRESS     = "0x66f5997b7810723aCeEeb8a880846fC117081bd0"; //MAINNET
  const MasterBaker_ADDRESS = "0x5510A90Ad92398B08C8Fb140816e5d9dFA4DA4f3"; //MAINNET
  const PAN_ABI         = require("./abis/PAN_abi.json");
  const ptCRO_ABI       = require("./abis/ptCRO_abi.json");
  const ytCRO_ABI       = require("./abis/ytCRO_abi.json");
  const Factory_ABI     = require("./abis/Factory_abi.json");
  const MasterBaker_ABI = require("./abis/MasterBaker_abi.json");
  const ptCRO_WCRO_address = "0xC34F77d6A65C2269a8b3926d4ED7F6f9Fb47EdDE"; //MAINNET
  const ytCRO_WCRO_address = "0x5c254a632fa7cfeE8B12350cC712366Ee3dcF082"; //MAINNET
  const PAN_WCRO_address   = "0x042d5453ec174305BfAF3F9cB6Ad9d665BA1165a"; //MAINNET
  const WCRO_address       = "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23"; //MAINNET
// SWAP 
  const uniswapRouterAddress = "0x145863Eb42Cf62847A6Ca784e6416C1682b1b2Ae"; //MAINNET VVS Router
  const [slippageTolerance, setSlippageTolerance] = useState(0.005); //0.5%
  const [tradeDeadLine, setTradeDeadLine] = useState(20);            //20mins
  const [multiHops, setMultiHops] = useState(true);                  //multiHops on
  const [tradeType, setTradeType] = useState(0); //0: "EXACT_INPUT"; 1: "EXACT_OUTPUT"
  const [swapCoin1, setSwapCoin1] = useState({
    name: "",
    address: "",
    symbol: "",
    decimals: 0,
    icon: "",
  });
  const [swapCoin2, setSwapCoin2] = useState({
    name: "",
    address: "",
    symbol: "",
    decimals: 0,
    icon: "",
  });
  const [swapPath, setSwapPath] = useState([]);
//
// Liquidity
const [liquidityCoin1, setLiquidityCoin1] = useState({
  name: "",
  address: "",
  symbol: "",
  decimals: 0,
  icon: "",
});
const [liquidityCoin2, setLiquidityCoin2] = useState({
  name: "",
  address: "",
  symbol: "",
  decimals: 0,
  icon: "",
});
//
  const [reserveRatio, setReserveRatio] = useState(0);
  const [stakedCRO, setStakedCRO]       = useState(0);
  const [unstakingCRO, setUnstakingCRO] = useState(0);
  const [web3Provider, setWeb3Provider] = useState(null);
  const [web3JsonRpcProvider, setWeb3JsonRpcProvider] = useState(null);
  const [web3Signer, setWeb3Signer] = useState(null);
  const [ptCROContract, setptCROContract] = useState(null);
  const [balanceCRO, setBalanceCRO] = useState(0);
  const [balanceWCRO, setBalanceWCRO] = useState(0);
  const [balancePAN, setBalancePAN] = useState(0);
  const [balanceptCRO, setBalanceptCRO] = useState(0);
  const [balanceytCRO, setBalanceytCRO] = useState(0);
  const [pendingRewards, setPendingRewards] = useState(0);
  const [amount, setAmount] = useState("");
  const [flexibleCRO, setFlexibleCRO] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSwapTab, setSelectedSwapTab] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [claimAddress, setClaimAddress] = useState("");
  const [blockNumber, setBlockNumber] = useState(0);
  const [color, setColor] = useState('rgb(55, 160, 105)');
  const [color2, setColor2] = useState('rgb(255, 255, 255)');
  const [aprCRO, setAprCRO] = useState(0);
  const [aprCronos, setAprCronos] = useState(0);
  const [panHodlLevel, setPanHodlLevel] = useState(0);
  const [holdDiscount, setHoldDiscount] = useState(0);
  const [burnFee, setBurnFee] = useState(0);
  const [myStakedPAN, setMyStakedPAN]   = useState(0);
  const [myPANRewards, setMyPANRewards] = useState(0);
  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const [selectedCoin, setSelectedCoin] = useState({
    name: "",
    symbol: "",
    icon: "",
  });

  const [ytTotalSupply, setYtTotalSupply]    = useState(0);
  const [delegationRaio, setDelegationRatio] = useState(0);
  const [utilization, setUtilization]        = useState(0);
  const [price_ptCRO, setPricePTCRO]         = useState(0);
  const [price_ytCRO, setPriceYTCRO]         = useState(0);
  const [price_PAN,   setPricePAN]           = useState(0);
  const [price_WCRO,  setPriceWCRO]          = useState(0);
  const [walletChainId, setWalletChainId]    = useState(0);

  //PAN page
  const [stakeingList, setStakingList] = useState([
    {
      name: "PAN",
      tokenName: "PAN",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: logo_color3_2,
      contractAddress: PAN_address,
      pid: "0",
      isPool: false
    },
    {
      name: "ptCRO",
      tokenName: "ptCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: ptCRO_logo,
      contractAddress: ptCRO_ADDRESS,
      pid: "1",
      isPool: false
    },
    {
      name: "ytCRO",
      tokenName: "ytCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: ytCRO_logo,
      contractAddress: ytCRO_ADDRESS,
      pid: "2",
      isPool: false
    },
  ]);

  const [poolList, setPoolList] = useState([
    {
      name: "PAN-WCRO LP",
      tokenName: "PAN",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  logo_color3_2,
      logo2: WCRO_logo,
      contractAddress: PAN_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "5",
      LPToTokenAmount: "1",
      isPool: true
    },
    {
      name: "ptCRO-WCRO LP",
      tokenName: "ptCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  ptCRO_logo,
      logo2: WCRO_logo,
      contractAddress: ptCRO_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "3",
      LPToTokenAmount: "1",
      isPool: true
    },
    {
      name: "ytCRO-WCRO LP",
      tokenName: "ytCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  ytCRO_logo,
      logo2: WCRO_logo,
      contractAddress: ytCRO_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "4",
      LPToTokenAmount: "1",
      isPool: true
    },
  ]);
  //

  //SWAP page
  const [pairInfo, setPairInfo] = useState([
    {
      myToken: 0,
      myShare: 0,
      poolTokenA: 0,
      poolTokenB: 0,

    },
    {
      myToken: 0,
      myShare: 0,
      poolTokenA: 0,
      poolTokenB: 0,
    },
    {
      myToken: 0,
      myShare: 0,
      poolTokenA: 0,
      poolTokenB: 0,
    },
  ]);
  //

  const contextValue = {
    ptCRO_ADDRESS,
    ptCRO_ABI,
    ytCRO_ADDRESS,
    ytCRO_ABI,
    Factory_ADDRESS,
    Factory_ABI,
    MasterBaker_ADDRESS,
    MasterBaker_ABI,
    cro_apiUrl,
    cronos_nodeUrl,
    chainId,
    ptCRO_WCRO_address,
    ytCRO_WCRO_address,
    PAN_WCRO_address,
    WCRO_address,
    USDT_address,
    PAN_address,
    uniswapRouterAddress,
    PAN_ABI,
    web3Provider,
    setWeb3Provider,
    web3JsonRpcProvider,
    setWeb3JsonRpcProvider,
    web3Signer,
    setWeb3Signer,
    ptCROContract,
    setptCROContract,
    balanceCRO,
    setBalanceCRO,
    balanceWCRO,
    setBalanceWCRO,
    balanceptCRO,
    setBalanceptCRO,
    balanceytCRO,
    setBalanceytCRO,
    balancePAN,
    setBalancePAN,
    pendingRewards,
    setPendingRewards,
    amount,
    setAmount,
    flexibleCRO,
    setFlexibleCRO,
    isLoading,
    setIsLoading,
    selectedTab,
    setSelectedTab,
    selectedSwapTab,
    setSelectedSwapTab,
    walletAddress,
    setWalletAddress,
    claimAddress,
    setClaimAddress,
    selectedCoin,
    setSelectedCoin,
    ytTotalSupply,
    setYtTotalSupply,
    delegationRaio,
    setDelegationRatio,
    utilization,
    setUtilization,
    price_ptCRO,
    setPricePTCRO,
    price_ytCRO,
    setPriceYTCRO,
    price_PAN,
    setPricePAN,
    price_WCRO,
    setPriceWCRO,
    blockNumber,
    setBlockNumber,
    color,
    setColor,
    color2,
    setColor2,
    aprCRO,
    setAprCRO,
    aprCronos,
    setAprCronos,
    panHodlLevel,
    setPanHodlLevel,
    holdDiscount,
    setHoldDiscount,
    burnFee,
    setBurnFee,
    reserveRatio,
    setReserveRatio,
    stakedCRO,
    setStakedCRO,
    unstakingCRO,
    setUnstakingCRO,
    isWalletOpen,
    setIsWalletOpen,
    slippageTolerance, setSlippageTolerance,
    tradeDeadLine, setTradeDeadLine,
    multiHops, setMultiHops,
    tradeType, setTradeType,
    swapCoin1, setSwapCoin1,
    swapCoin2, setSwapCoin2,
    swapPath, setSwapPath,
    liquidityCoin1, setLiquidityCoin1,
    liquidityCoin2, setLiquidityCoin2,
    walletChainId, setWalletChainId,
    myStakedPAN, setMyStakedPAN,
    myPANRewards, setMyPANRewards,
    stakeingList, setStakingList,
    poolList, setPoolList,
    pairInfo, setPairInfo,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
