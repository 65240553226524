import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Box, Text, Circle, Image, Divider, useDisclosure } from "@chakra-ui/react";
import {ChevronUpIcon, ChevronDownIcon, ExternalLinkIcon} from "@chakra-ui/icons";
import { useWeb3 } from "../hooks/useWeb3";
import { usePAN } from "../hooks/usePAN";
import CustomSwitch  from "./CustomSwitch";
import MintButtonSmall from "./MintButtonSmall";
import ClaimButtonSmall from "./ClaimButtonSmall";
import AddLiquidModal from "./AddLiquidModal";
import RemoveLiquidModal from "./RemoveLiquidModal";

import { AppContext } from "../context";

export default function FlexLiquidityButton({ pair, isFirst, isLast, pid, contractAddress, myToken, poolTokenA, poolTokenB, myShare}) {
  const { handleAddLP, handleExplorer } = useWeb3();
  const { checkAllowance, getBalance, getStakeAmount, claimRewards} = usePAN();

  const [detailOpen, setDetailOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stakeChecked, setStakeChecked] = useState(true);
  const [allowed, setAllowed] = useState(true);

  const toggleSwitch = () => setStakeChecked(!stakeChecked);
  const changeDetailOpen = () => setDetailOpen(!detailOpen);
  
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const {
    web3Provider,
    walletAddress,
    MasterBaker_ADDRESS,
  } = useContext(AppContext);

  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const checkStatus = async (amount) => {
    console.log("check");
    if (web3Provider) {
      try {
        var result = await checkAllowance(contractAddress, amount, MasterBaker_ADDRESS);

        setAllowed( result ); 
        console.log(amount, allowed, result, contractAddress);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const setAmountMAX = async () => {
    var max;
    var currentAddress = await web3Provider.getSigner().getAddress();
    if (stakeChecked) {
      max = await getBalance(contractAddress, currentAddress);
      //console.log(Number(max));
      //setAmount( (Math.floor(Number(max)*10)/10) );
      console.log(max);
      console.log((Math.floor(Number(max)*10)/10));
      //setAmount( ((Math.floor(Number(max)*10)/10).toString()) );
      checkStatus(max);
    }
    else {
      max = await getStakeAmount(pid);
      //console.log(Number(max));
      //setAmount( ((Math.floor(Number(max)*10)/10).toString()) );
      checkStatus(max);
    }
  }

  return (
    <Box bg="green.50" borderRadius={ isFirst ? "1.25rem 1.25rem 0rem 0rem" : (isLast ? "0rem 0rem 1.25rem 1.25rem" : "0rem 0rem 0rem 0rem")}>
    <AddLiquidModal pair={pair} isOpen={isOpen1} onClose={onClose1} />
    <RemoveLiquidModal pair={pair} isOpen={isOpen2} onClose={onClose2} />

    <Flex key={pair.name} justifyContent="space-between" p="1rem 1rem 1rem 1rem" alignItems="center">
      <Flex alignItems="center" w="14rem">
        <Circle size="40px" position="absolute" left="10px" bg="white" color="white" zIndex="1">
          <Image src={pair.icon1} fit="contain" borderRadius="1.25rem" style={{ zIndex: 1 }}/>
        </Circle>
        <Circle size="40px" position="absolute" left="35px" bg="blue.100" color="white" zIndex="1">
          <Image src={pair.icon2} fit="contain" borderRadius="1.25rem" style={{ zIndex: 1 }}/>
        </Circle>
        <Text ml="70px" fontWeight="bold" >{pair.name}</Text>
      </Flex>
      
      <Box align="left" w="140px"><Text fontSize="16px" color="#551a8b" fontWeight="bold">My tokens:</Text>     <Text fontSize="18px" fontWeight="bold">{Number(myToken).toLocaleString("en-US", {minimumFractionDigits: 3})}</Text></Box>
      <Box align="left" w="140px"><Text fontSize="16px" color="#551a8b" fontWeight="bold">My pool share:</Text> <Text fontSize="18px" fontWeight="bold">{Number(myShare).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</Text></Box>
      <Box align="left" w="140px"><Text fontSize="16px" color="#551a8b" fontWeight="bold">Pooled {pair.symbolA}:</Text>      <Text fontSize="18px" fontWeight="bold">{Number(poolTokenA).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Text></Box>
      <Box align="left" w="140px"><Text fontSize="16px" color="#551a8b" fontWeight="bold">Pooled {pair.symbolB}:</Text>          <Text fontSize="18px" fontWeight="bold">{Number(poolTokenB).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}</Text></Box>

      <Flex justifyContent="right" w="20px" ml="3rem">
      <Text fontSize="15px" color="blue.600" fontWeight="bold" cursor="pointer" onClick={changeDetailOpen}> {detailOpen ? "Hide" : "Details"} </Text>
        {detailOpen ? ( <ChevronUpIcon   fontSize="1.25rem" cursor="pointer" color="blue.600" onClick={changeDetailOpen} /> )
                    : ( <ChevronDownIcon fontSize="1.25rem" cursor="pointer" color="blue.600" onClick={changeDetailOpen} /> )
        }
      </Flex>
    </Flex>

  {((!isLast)||detailOpen) && <Divider color="gray"></Divider>}

  {detailOpen &&
   <Box bg="rgb(246,246,246)" borderRadius={ isLast ? "0rem 0rem 1.25rem 1.25rem" : "0rem 0rem 0rem 0rem" }>
    <Flex justifyContent="space-evenly" alignItems="center" p="1.2rem">

      <Box border="0px" borderColor="gray.300" borderRadius="0.65rem" mr="1.5rem" p={1} display="flex" flexDirection="column" alignItems="start">
        <Flex alignItems="center">
          <Text fontSize="14px" color="blue.700" fontWeight="bold" mr="0.3rem" style={{ cursor: 'pointer' }} onClick={() => handleExplorer( ("/address/"+contractAddress) )}>View Contract</Text>
          <ExternalLinkIcon boxSize={5} color="blue.700" style={{ cursor: 'pointer' }} onClick={() => handleExplorer( ("/address/"+contractAddress) )}/>
        </Flex>
      </Box>

      <Box width="12rem"><ClaimButtonSmall onClick={onOpen1} isLoading={false} text="Add"/></Box>
      <Box width="12rem"><ClaimButtonSmall onClick={onOpen2} isLoading={false} text="Remove"/></Box>

    </Flex>
    {!isLast && <Divider color="gray"></Divider>}
   </Box>
  }

  </Box>
  );
}