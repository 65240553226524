import React, { useState, useContext } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, Spacer, useDisclosure, Image } from "@chakra-ui/react";
import { SettingsIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import ClaimButton from "./components/ClaimButton";
import TokenSelect from "./components/TokenSelect";
import TokenModal from "./components/TokenModal";
import { useWeb3 } from "./hooks/useWeb3";
import { useIcon } from "./hooks/useIcon";
import { useFactory } from "./hooks/useFactory";

import logo_cronos from "./assets/cronos_light_bg.png";

import { AppContext } from "./context";

function Claim() {
    const {
        web3Provider,
        pendingRewards,
        isLoading,
        walletAddress,
        selectedCoin,
        blockNumber,
        color,
        color2
      } = useContext(AppContext);

      const { isOpen, onOpen, onClose } = useDisclosure();

      const { handleExplorer } = useWeb3();
      const { CircleIcon } = useIcon();
      const { claimRewards } = useFactory();

      return (
        <Box
        className="container"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                           : "92.3vh"}
        overflow={(isMobile) ? "scroll" : "hidden" }
        >
          <TokenModal isOpen={isOpen} onClose={onClose} />

          <Box
          w={(isMobile) ? "98vw" : "46rem"}
          mx="auto"
          position="relative"
          top={(isAndroid) ? "10vh" : "100px"} 
          boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
          borderRadius="1.25rem">
      
            <Flex
              alignItems="center"
              p="1rem 1rem 1rem"
              bg="white"
              color="rgb(86, 90, 105)"
              justifyContent="space-between"
              borderRadius="1.25rem 1.25rem 0 0">
              <Text
                color="black"
                fontWeight="bold">
                Rewards
              </Text>
            </Flex>
      
            <Box
              p="0rem 0.5rem 0.5rem 0.5rem"
              bg="white"
              borderRadius="0 0 1.25rem 1.25rem">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="rgb(247, 248, 250)"
                p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                <Box>
                  <Text
                    color="black"
                    fontWeight="bold">
                    {(selectedCoin.symbol === "") ? "CRO" : selectedCoin.symbol}:
                  </Text>
                </Box>

                <Box width="86%">
                  <Text
                    fontWeight="500"
                    fontSize="1.4rem"
                    width="100%"
                    size="19rem"
                    bg="rgb(247, 248, 250)"
                    textAlign="right"
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    borderRadius="0.25rem"
                    color="black"
                  >{pendingRewards}</Text>
                </Box>
              </Flex>
            </Box>
    
          </Box>

          <Box
          w={(isMobile) ? "98vw" : "46rem"}
          mx="auto"
          position="relative"
          top={(isMobile) ? "10vh" : "-20px"} 
          boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
          borderRadius="1.25rem">
      
            <Flex
              alignItems="center"
              p="1rem 1rem 1rem"
              bg="white"
              color="rgb(86, 90, 105)"
              justifyContent="space-between"
              borderRadius="1.25rem 1.25rem 0 0">
              <Text
                color="black"
                fontWeight="bold">
                Claim Rewards of
              </Text>
              <SettingsIcon
                fontSize="1.25rem"
                cursor="pointer"
                _hover={{ color: "rgb(128,128,128)" }}
              />
            </Flex>
      
            <Box
              p="0rem 0.5rem 0.5rem 0.5rem"
              bg="white"
              borderRadius="0 0 0rem 0rem">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="rgb(247, 248, 250)"
                p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                <Box>
                  <Text
                    color="black"
                    fontSize={(isMobile) ? "0.8rem" : "1rem"}
                    fontWeight="bold">
                    Address:
                  </Text>
                </Box>

                <Box width="86%">
                  <Text
                    fontWeight="500"
                    fontSize={(isMobile) ? "0.8rem" : "1.4rem"}
                    width={(isMobile) ? "98%" : "100%"}
                    size="19rem"
                    bg="rgb(247, 248, 250)"
                    textAlign="right"
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    borderRadius="0.25rem"
                    color="black"
                  >{walletAddress}</Text>
                </Box>
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="rgb(247, 248, 250)"
                p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "1rem 1rem 1rem"}
                mt="0.25rem"
                borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                height="75px"
                _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                >
                <Box>
                  <Text
                    color="black"
                    fontSize={(isMobile) ? "0.8rem" : "1rem"}
                    fontWeight="bold">
                    With:
                  </Text>
                </Box>
                <Box>
                  <Input
                    placeholder=""
                    fontWeight="500"
                    fontSize="1.4rem"
                    width="100%"
                    size="19rem"
                    textAlign="right"
                    bg="rgb(247, 248, 250)"
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    borderRadius="0.25rem"
                    type="text"
                    inputMode="decimal"
                    color="black"
                    value={""}
                    isDisabled
                    style={{ boxShadow: "none", cursor: "auto" }}
                  />
                </Box>
                <Box ml="0.5rem">
                <TokenSelect openTokenModal={onOpen} value={selectedCoin.symbol} image={selectedCoin.icon}/>
                </Box>
              </Flex>

            </Box>
      
            <ClaimButton onClick={claimRewards} isLoading={isLoading} text="Claim"/> 
          </Box>

          <Flex bottom="0" justify="space-between" alignItems="center" width="100vw" mb={(isAndroid) ? "1.6rem" : "0.2rem"} mt={(isAndroid) ? "2.2rem" : "0.2rem"}>

            <Flex mr="16px" w="90px">
            </Flex>

            <Flex alignItems="center">
              <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
              <Box width="85px" height="30px">
                <Image src={logo_cronos} fit="contain"/>
              </Box>
            </Flex>

            <Flex alignItems="center">
              {web3Provider && (
              <Flex alignItems="center">
              <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color={color2} mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
              <CircleIcon boxSize={3} color={color} mr={(isMobile) ? '0px' : '16px'}/>
              </Flex>
              )}
              {!web3Provider && (
              <Flex alignItems="center">
              <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
              <CircleIcon boxSize={3} color="transparent" mr={(isMobile) ? '0px' : '16px'}/>
              </Flex>
              )}
            </Flex>

          </Flex>
        
        </Box>
      );
  
}

export default Claim;
