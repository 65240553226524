import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, useDisclosure, Image, useToast } from "@chakra-ui/react";
import { SettingsIcon, ArrowDownIcon, RepeatIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useSwap } from "./hooks/useSwap";
import { useIcon } from "./hooks/useIcon";
import { useFactory } from "./hooks/useFactory";

import SwitchSwapTab from "./components/SwitchSwapTab";
import FlexLiquidityButton from "./components/FlexLiquidityButton";
import LiquidityButton from "./components/LiquidityButton";
import SwapTokenSelect from "./components/SwapTokenSelect";
import SwapModal from "./components/SwapModal";
//import SwapSettingModal from "./components/SwapSettingModal";

import logo_cronos from "./assets/cronos_light_bg.png";

import { AppContext } from "./context";

import pairList from "./pairList.json";
import { logoMapping } from './logoMapping';

pairList.forEach((pair) => {
  if (logoMapping[pair.tokenA]) { pair.icon1 = logoMapping[pair.tokenA]; }
  if (logoMapping[pair.tokenB]) { pair.icon2 = logoMapping[pair.tokenB]; }
});

function Swap() {
    const {
        pairInfo,
        setPairInfo,
        uniswapRouterAddress,
        web3Provider,
        isLoading, setIsLoading,
        walletAddress,
        balanceCRO,
        selectedSwapTab,
        tradeType,
        setTradeType,  //0: exact input; 1: exact output
        swapCoin1,
        setSwapCoin1,
        swapCoin2,
        setSwapCoin2,
        swapPath,
        setSwapPath,
        blockNumber,
      } = useContext(AppContext);

      const [tokenNumber, setTokenNumber] = useState(1);

      const [amount1,          setAmount1]          = useState("");
      const [amount1Tolerance, setAmount1Tolerance] = useState("");
      const [amount2,          setAmount2]          = useState("");
      const [amount2Tolerance, setAmount2Tolerance] = useState("");
      const [priceInToOut,     setPriceInToOut]     = useState("");
      const [priceOutToIn,     setPriceOutToIn]     = useState("");

      const [myCoin1Balance,   setMyCoin1Balance]   = useState(0);
      const [myCoin2Balance,   setMyCoin2Balance]   = useState(0);
      const [allowed, setAllowed]                         = useState(true);
      const [isSetAmount, setIsSetAmount]                 = useState(false);
      const [isCoinSwapDisabled, setIsCoinSwapDisabled]   = useState(false);      
      const [balanceSufficient,   setBalanceSufficient]   = useState(true);
      const [liquiditySufficient, setLiquiditySufficient] = useState(true);
      const [isUpdate, setIsUpdate] = useState(false);
      const [priceSwitch,   setPriceSwitch] = useState(0);

      const toast = useToast();

      const { isOpen: isOpen,  onOpen: onOpen,  onClose: onClose } = useDisclosure();

      const { handleExplorer } = useWeb3();
      const { checkAllowance, checkAllowance_decimals, approveToken, approveToken_decimals, getTotalSupply, getBalance, getBalance_decimals} = usePAN();
      const { calAmountOUT, calTradePath, TokenSwap, SwapFunc, addLiquidity, removeLiquidity } = useSwap();
      const { CircleIcon } = useIcon();
      const { mint_ptCRO } = useFactory();

      const tabs = ["Swap", "Liquidity"];

      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

      const handleTxCPLT = (desc, text) => {
        toast({
          title: desc + " is completed !",
          description: "Block Number: " + text,
          status: "success",
          duration: 10000,
          isClosable: true,
          containerStyle: {
            width: (isMobile) ? "96vw" : "" ,
            fontSize: (isMobile) ? "14px" : "" ,
          },
        });
      };

      const setAmountMAX = async (coinNumber) => {
        if      ((coinNumber===1) && (isSetAmount===false)) {
          setIsSetAmount(true);
          setAmount1(myCoin1Balance); setTradeType(0);
          await delay(1500);  //add wait time for 1.5s
          setIsSetAmount(false);
        }
        else if ((coinNumber===2) && (isSetAmount===false)) {
          setIsSetAmount(true);
          setAmount2(myCoin2Balance); setTradeType(1);
          await delay(1500);  //add wait time for 1.5s
          setIsSetAmount(false);
        }
      }

      async function coinSwap() { //HERE!!

        var tempCoin1;
        var tempCoin2;
        tempCoin1 = {name: swapCoin1.name, address: swapCoin1.address, symbol: swapCoin1.symbol, decimals: swapCoin1.decimals, chainId: swapCoin1.chainId, icon: swapCoin1.icon};
        tempCoin2 = {name: swapCoin2.name, address: swapCoin2.address, symbol: swapCoin2.symbol, decimals: swapCoin2.decimals, chainId: swapCoin2.chainId, icon: swapCoin2.icon};

        setSwapCoin2({name: tempCoin1.name, address: tempCoin1.address, symbol: tempCoin1.symbol, decimals: tempCoin1.decimals, chainId: tempCoin1.chainId, icon: tempCoin1.icon});
        setSwapCoin1({name: tempCoin2.name, address: tempCoin2.address, symbol: tempCoin2.symbol, decimals: tempCoin2.decimals, chainId: tempCoin2.chainId, icon: tempCoin2.icon});
        
        var tempAmount;
        var tempAmountTolerance;
        var tempTradeType;
        var oriTradeType = tradeType;

        if (oriTradeType===0) { setTradeType(1); tempTradeType=1; tempAmount = amount1; tempAmountTolerance = amount1Tolerance;} //Exact input to exact output
        else                  { setTradeType(0); tempTradeType=0; tempAmount = amount2; tempAmountTolerance = amount2Tolerance;} //Exact output to exact input

        if (oriTradeType===0) { setAmount1("");         setAmount1Tolerance("");
                                setAmount2(tempAmount); setAmount2Tolerance(tempAmountTolerance);    

                              } //Exact input to exact output

        else                  { setAmount1(tempAmount); setAmount1Tolerance(tempAmountTolerance);
                                setAmount2("");         setAmount2Tolerance("");

                              } //Exact output to exact input

      }

      const checkStatus = async (coin1Symbol, coin1Addr, amount, decimals) => {
        if (web3Provider) {
          try {
            if ((coin1Symbol !== "CRO")&&(amount!=="")) {
              var result = await checkAllowance_decimals(coin1Addr, amount, uniswapRouterAddress, decimals);
              setAllowed( result ); 
            } else {
              setAllowed( true ); 
            }
          } catch (error) {
            console.error(error);
          }
        }
      };

      const checkTokenAmount = async (amount1, amount2, tradTYPE) => {
        var myBalance;

        if (web3Provider) {
          try {

            if ((amount1!=="")&&(amount2!=="")) {

              if (swapCoin1.symbol !== "CRO") {
                myBalance = await getBalance_decimals(swapCoin1.address, walletAddress, swapCoin1.decimals);
                //console.log(myBalance, swapCoin1.address);

              } else {
                myBalance = balanceCRO;
              }
              //console.log(myBalance, amount1, swapCoin1.address);
              if (Number(myBalance)>=Number(amount1)) {
                setBalanceSufficient(true);
              } else {
                setBalanceSufficient(false);
              }
            }

            if ((tradTYPE===1)&&(amount1==="")&&(amount2!=="")) {
              setLiquiditySufficient(false);
            } else {
              setLiquiditySufficient(true);
            }

          } catch (error) {
            console.error(error);
          }
        }
      };

      const updatePairInfo = (i, newMyToken, newMyShare, newPoolTokenA, newPoolTokenB) => {
        setPairInfo((prevPairInfo) => [
          ...prevPairInfo.slice(0, i),
          {
            myToken: newMyToken,
            myShare: newMyShare,
            poolTokenA: newPoolTokenA,
            poolTokenB: newPoolTokenB,
          },
          ...prevPairInfo.slice(i+1), // Keep the rest of the items the same
        ]);
      };

      const updateStatus = async () => {
        var obj;
  
        if ((web3Provider)&&(walletAddress!=="")) {
          try {
            //console.log(amount1, amount1Tolerance, amount2, amount2Tolerance, isUpdate);
            
            if (isUpdate===false)  //Update pools information
            {
              setIsUpdate(true);
              for (let i=0; i < pairList.length; i++)
              {
                let LPtotalsupply;
                let myToken;
                let myShare;
                let poolTokenA;
                let poolTokenB;
  
                //console.log(pairList[i].liquidityToken_addr, pairList[i].tokenA_addr, pairList[i].tokenB_addr, walletAddress);
                LPtotalsupply = await getTotalSupply(pairList[i].liquidityToken_addr);
                poolTokenA    = await getBalance(pairList[i].tokenA_addr, pairList[i].liquidityToken_addr);
                poolTokenB    = await getBalance(pairList[i].tokenB_addr, pairList[i].liquidityToken_addr);
                myToken       = await getBalance(pairList[i].liquidityToken_addr, walletAddress);
                myShare       = Number(Number(myToken)*100 / Number(LPtotalsupply)).toFixed(2);
                //console.log(LPtotalsupply, poolTokenA, poolTokenB, myToken, myShare);
                //console.log("SWAP update", i , Date.now()/1000);
                updatePairInfo(i, myToken, myShare, poolTokenA, poolTokenB);
              }
              await delay(5000);
              setIsUpdate(false);
            }
  
            if ((tradeType===0)&&(!isLoading)) { // EXACT INPUT
  
              if ( (swapCoin1.name!=="") && (swapCoin2.name!=="") && (Number(amount1)>0) ) {
                obj = await calAmountOUT(amount1, swapCoin1, swapCoin2, 0);
                setAmount2(obj.amount); setAmount2Tolerance(obj.amountTolerance);
  
                checkTokenAmount(amount1, obj.amount, 0);
                checkStatus(swapCoin1.symbol, swapCoin1.address, amount1, swapCoin1.decimals);
                setSwapPath(obj.path);
                setPriceInToOut(obj.priceInToOut);
                setPriceOutToIn(obj.priceOutToIn);

                //console.log(obj);
                //console.log(priceInToOut, priceOutToIn);
                //console.log("done");
              }
              else {
                setAmount2(""); setAmount2Tolerance("");
              }
  
            }
            else if (!isLoading) {               // EXACT OUTPUT
              
              if ( (swapCoin1.name!=="") && (swapCoin2.name!=="") && (Number(amount2)>0) ) {
                obj = await calAmountOUT(amount2, swapCoin1, swapCoin2, 1);
                setAmount1(obj.amount); setAmount1Tolerance(obj.amountTolerance);
  
                checkTokenAmount(ethers.utils.formatUnits(obj.amountTolerance, swapCoin1.decimals), amount2, 1);
                checkStatus(swapCoin1.symbol, swapCoin1.address, amount1, swapCoin1.decimals);
                setSwapPath(obj.path);
                setPriceInToOut(obj.priceInToOut);
                setPriceOutToIn(obj.priceOutToIn);

                console.log(priceInToOut, priceOutToIn);
                //console.log("done");
              } 
              else {
                setAmount1(""); setAmount1Tolerance("");
              }
  
            }

            if ((swapCoin1.name!=="")) {  //Update Coin1 Balance
              if (swapCoin1.symbol !== "CRO") {
                var coin1Bal = await getBalance_decimals(swapCoin1.address, walletAddress, swapCoin1.decimals);
                setMyCoin1Balance(coin1Bal);
              } else {
                setMyCoin1Balance(balanceCRO);
              }
            }

            if ((swapCoin2.name!=="")) {  //Update Coin2 Balance
              if (swapCoin2.symbol !== "CRO") {
              var coin1Ba2 = await getBalance_decimals(swapCoin2.address, walletAddress, swapCoin2.decimals);
                setMyCoin2Balance(coin1Ba2);
              } else {
                setMyCoin2Balance(balanceCRO);
              }
            }

          } catch (error) {
            console.error(error);
          }
        }
      };

  //regular update states
  useEffect(() => {
    let intervalId;

    if (web3Provider) {
      updateStatus();
      intervalId = setInterval(() => {updateStatus();}, 25000);
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider, walletAddress, amount1, amount2, isUpdate, isOpen, isLoading, tradeType]);
  ///////////////////////

  return (
    <Box
    align="center"                                 
    display="flex"                                 
    flexDirection="column"                         
    justifyContent="space-between"                 
    height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                       : "92.3vh"}                                              
    css={{                                         
      overflowY: "scroll",                         
      "&::-webkit-scrollbar": {                    
        width: (isMobile) ? '0px' : '16px',                             
      },                                           
      "&::-webkit-scrollbar-track": {              
        backgroundColor: "transparent",            
      },                                           
      "&::-webkit-scrollbar-thumb": {              
        backgroundColor: "rgba(205, 102, 0, 0.5)", 
        borderRadius: "20px",                      
        border: "4px solid transparent",           
        backgroundClip: "content-box",             
      },                                           
      "&::-webkit-scrollbar-thumb:hover": {        
        backgroundColor: "rgba(205, 102, 0, 0.8)", 
      },                                           
    }} 
    >
    <SwapModal isOpen={isOpen} onClose={onClose} tokenNumber={tokenNumber}/>

    <Box >

      <Flex justifyContent="center" alignItems="center">
        <Box position="relative" top="60px" borderRadius="2rem" bg="orange.200" border="2px" borderColor="orange.200" p="0.4rem">
          <SwitchSwapTab tabs={tabs} onChange={()=>{}} />
        </Box>
      </Flex>
      
      { (selectedSwapTab===0) && //SWAP
      
      <Box
      w={(isMobile) ? "95vw" : "33rem"}
      mx="auto"
      position="relative"
      top={(isMobile) ? "80px" : "150px"}
      boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
      borderRadius="1.25rem">

        <Flex
          alignItems="center"
          p="1rem 1rem 1rem"
          bg="green.50"
          color="rgb(86, 90, 105)"
          justifyContent="space-between"
          borderRadius="1.25rem 1.25rem 0 0">
          <Text
            color="black"
            fontWeight="bold">
            Swap through VVS V2
          </Text>
          <SettingsIcon
            fontSize="1.25rem"
            cursor="pointer"
            _hover={{ color: "rgb(128,128,128)" }}
          />
        </Flex>

        <Box
          p="0rem 0.5rem 0.5rem 0.5rem"
          bg="green.50"
          borderRadius="0 0 0rem 0rem">
          <Box borderRadius="1.25rem" border="0.06em solid rgb(237, 238, 242)" _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              bg="rgb(247, 248, 250)"
              p="1.5rem 1rem 1rem"
              borderRadius="1.25rem 1.25rem 0rem 0rem" border="0em solid rgb(237, 238, 242)"
              height="10px">
              <Box>
                <Text
                  color="gray.500"
                  fontWeight="bold">
                  From:
                </Text>
              </Box>
              <Box>
                { (swapCoin1.name!=="") &&
                <Text
                  cursor="pointer"
                  color="gray.500"
                  fontWeight="bold"
                  onClick={ async () => {setAmountMAX(1);} }>
                  Balance: {Number(myCoin1Balance).toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3})}
                </Text>
                }
                { (swapCoin1.name==="") &&
                <Text
                  cursor="pointer"
                  color="gray.500"
                  fontWeight="bold">
                  -
                </Text>
                }
              </Box>            
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="space-between"
              bg="rgb(247, 248, 250)"
              p="0rem 1rem 1rem"
              borderRadius="0rem 0rem 1.25rem 1.25rem" border="0rem solid rgb(237, 238, 242)"
              height="60px">
              <Flex
                alignItems="center"
                justifyContent="center"
                bg="green.50"
                p="0.18rem"
                borderRadius="1.25rem"
                pos="absolute"
                top="9.1rem"
                left="50%"
                transform="translateX(-50%)"
                w="2rem">
                <ArrowDownIcon
                  bg={isCoinSwapDisabled ? "rgb(160,190,190)" : "rgb(247, 248, 250)"}
                  _hover={(isCoinSwapDisabled ? {cursor: "default"} : {cursor: "pointer"})}
                  color="rgb(128,128,128)"
                  h="1.5rem" width="1.62rem"
                  borderRadius="1.25rem"
                  onClick={((!isCoinSwapDisabled)&&(swapCoin1.name!=="")&&(swapCoin2.name!=="")&&(amount1!=="")&&(amount2!=="")) ? async () => {//coinSwap(); HERE!!
                                                                                                                                                //setIsCoinSwapDisabled(true);
                                                                                                                                                //await delay(10000);
                                                                                                                                                //setIsCoinSwapDisabled(false);
                                                                                                                                                } : null }                  
                />
              </Flex>
              <Box width="100%">
                <Input
                  placeholder="0.0"
                  fontWeight="500"
                  fontSize="1.4rem"
                  width="100%"
                  size="19rem"
                  textAlign="right"
                  bg="rgb(247, 248, 250)"
                  outline="none"
                  border="none"
                  focusBorderColor="none"
                  borderRadius="0.25rem"
                  type="text"
                  inputMode="decimal"
                  color="black"
                  value={amount1}
                  style={{ boxShadow: "none" }}
                  onChange={ async (e)  => {var objOUT = {amount: "", amountTolerance: ""};
                                            var typingTimeout;
                                            const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount1(newAmount); setTradeType(0);
                                          }}
                />
              </Box>
              <Box ml="0.5rem">
              <SwapTokenSelect openTokenModal={onOpen} setTokenNumber={setTokenNumber} tokenNumber={1} value={swapCoin1.symbol} image={swapCoin1.icon}/>
              </Box>
            </Flex>
          </Box>

          <Box mt="0.5rem" borderRadius="1.25rem" border="0.06em solid rgb(237, 238, 242)" _hover={{ border: "0.06rem solid rgb(211,211,211)" }}> 
            <Flex
              alignItems="center"
              justifyContent="space-between"
              bg="rgb(247, 248, 250)"
              p="1.5rem 1rem 1rem"
              borderRadius="1.25rem 1.25rem 0rem 0rem" border="0em solid rgb(237, 238, 242)"
              height="10px">
              <Box>
                <Text
                  color="gray.500"
                  fontWeight="bold">
                  To:
                </Text>
              </Box>
              <Box>
                { (swapCoin2.name!=="") &&
                <Text
                  cursor="pointer"
                  color="gray.500"
                  fontWeight="bold"
                  onClick={ async () => {setAmountMAX(2);} }>
                  Balance: {Number(myCoin2Balance).toLocaleString("en-US", {maximumFractionDigits: 3, minimumFractionDigits: 3})}
                </Text>
                }
                { (swapCoin2.name==="") &&
                <Text
                  cursor="pointer"
                  color="gray.500"
                  fontWeight="bold">
                  -
                </Text>
                }
              </Box>            
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="space-between"
              bg="rgb(247, 248, 250)"
              p="0rem 1rem 1rem"
              borderRadius="0rem 0rem 1.25rem 1.25rem" border="0rem solid rgb(237, 238, 242)"
              height="60px">
              <Box width="100%">
                <Input
                  placeholder="0.0"
                  fontWeight="500"
                  fontSize="1.4rem"
                  width="100%"
                  size="19rem"
                  textAlign="right"
                  bg="rgb(247, 248, 250)"
                  outline="none"
                  border="none"
                  focusBorderColor="none"
                  borderRadius="0.25rem"
                  type="text"
                  inputMode="decimal"
                  color="black"
                  value={amount2}
                  style={{ boxShadow: "none" }}
                  onChange={ async (e)  => {var objOUT = {amount: "", amountTolerance: ""};
                                            var typingTimeout;
                                            const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount2(newAmount); setTradeType(1);
                                          }}
                />
              </Box>
              <Box ml="0.5rem">
              <SwapTokenSelect openTokenModal={onOpen} setTokenNumber={setTokenNumber} tokenNumber={2} value={swapCoin2.symbol} image={swapCoin2.icon}/>
              </Box>
            </Flex>

          </Box>

          <Flex
              mt="0.2rem"
              alignItems="center"
              justifyContent="space-between"
              bg="green.50"
              p="1rem 0.5rem 1rem 1rem"
              borderRadius="0rem" border="0px solid rgb(237, 238, 242)"
              height="20px">
              <Box>
                <Text
                  color="gray.500"
                  fontSize="1rem"
                  fontWeight="bold">
                  Price:
                </Text>
              </Box>

              <Flex alignItems="center" border="0px">

              { (priceSwitch===0) && (swapCoin1.symbol!=="") && (swapCoin2.symbol!=="") && (amount1!=="") && (amount2!=="") &&
                <Box>
                  <Text
                    color="gray.500"
                    fontSize="1rem"
                    fontWeight="bold">
                    1 {swapCoin1.symbol} = {priceInToOut} {swapCoin2.symbol}
                  </Text>
                </Box> }

                { (priceSwitch===1) && (swapCoin1.symbol!=="") && (swapCoin2.symbol!=="") && (amount1!=="") && (amount2!=="") &&
                <Box>
                  <Text
                    color="gray.500"
                    fontSize="1rem"
                    fontWeight="bold">
                    1 {swapCoin2.symbol} = {priceOutToIn} {swapCoin1.symbol}
                  </Text>
                </Box> }

                { (swapCoin1.symbol!=="") && (swapCoin2.symbol!=="") && (amount1!=="") && (amount2!=="") &&
                <RepeatIcon
                  ml="0.4rem"
                  color="gray.500"
                  fontSize="1rem"
                  cursor="pointer"
                  _hover={{ color: "rgb(80,80,80)" }}
                  onClick={ () => { var newSwitch = (priceSwitch===0) ? 1 : 0 ;
                                    setPriceSwitch(newSwitch);
                                  } }
                /> }

              </Flex>
            </Flex>

        </Box>

        { (liquiditySufficient===true) && (balanceSufficient===false) &&         <LiquidityButton onClick={ async () => {} }
                                                                                                  isLoading={isLoading} isDisable={true} text="Insufficient Balance" allowed={false} /> }
        { (liquiditySufficient===false) &&                                       <LiquidityButton onClick={ async () => {} }
                                                                                                  isLoading={isLoading} isDisable={true} text="Insufficient Liquidity" allowed={false} /> }

        { ((balanceSufficient===true)&&(liquiditySufficient===true)&&(allowed===false)) && <LiquidityButton onClick={ async () => {if (amount1Tolerance!=="") { var amount1Tolerance_formatUnits = ethers.utils.formatUnits(amount1Tolerance, swapCoin1.decimals); }

                                                                                                                                   var tx = (tradeType===0) ? await approveToken_decimals(swapCoin1.address, uniswapRouterAddress, amount1                     , swapCoin1.decimals)  //Exact ipnut
                                                                                                                                                            : await approveToken_decimals(swapCoin1.address, uniswapRouterAddress, amount1Tolerance_formatUnits, swapCoin1.decimals); //Exact output 
                                                                                                                                   if (typeof tx !== 'undefined') {
                                                                                                                                   setIsLoading(true);
                                                                                                                                   var receipt = await tx.wait(); //await tx
                                                                                                                                   await delay(10000);
                                                                                                                                   if (receipt.status === 0) { console.log("tx failed"); }
                                                                                                                                   if (receipt.status === 1) { handleTxCPLT("ApproveToken", receipt.blockNumber); }
                                                                                                                                   setIsLoading(false);
                                                                                                                                   //checkStatus(swapCoin1.symbol, swapCoin1.address, amount1);HERE!
                                                                                                                                  }
                                                                                                                    }}
                                                                                                           isLoading={isLoading} isDisable={(amount1==="")} text={"Approve " + swapCoin1.symbol} allowed={false} /> }

        { ((balanceSufficient===true)&&(liquiditySufficient===true)&&(allowed===true)) && <LiquidityButton onClick={ async () => { var tx = await SwapFunc(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType);
                                                                                                                                   if (typeof tx !== 'undefined') {
                                                                                                                                   setIsLoading(true);
                                                                                                                                   var receipt = await tx.wait(); //await tx
                                                                                                                                   await delay(10000);
                                                                                                                                   if (receipt.status === 0) { console.log("tx failed"); }
                                                                                                                                   if (receipt.status === 1) { handleTxCPLT("Swap", receipt.blockNumber); }
                                                                                                                                   setIsLoading(false); }
                                                                                                                    }}
                                                                                                           isLoading={isLoading}
                                                                                                           isDisable={((swapCoin1.name==="")||(swapCoin2.name==="")||(amount1==="")||(amount2===""))}
                                                                                                           text="Swap" allowed={true} /> }

      </Box>

    }

    { (selectedSwapTab===1) && //LIQUIDITY
      <Box 
        w="60rem"
        mx="auto"
        position="relative"
        top={ (isMobile) ? '80px' : '150px' }
        boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
        borderRadius="1.25rem"
      >
      {pairList.map((val, index) => (
              <FlexLiquidityButton key={val.name} pair={val} isFirst={(index===0) ? true : false} isLast={(index===(pairList.length-1)) ? true : false} pid={0}
                                   contractAddress={val.liquidityToken_addr} myToken={pairInfo[index].myToken} myShare={pairInfo[index].myShare}
                                   poolTokenA={pairInfo[index].poolTokenA} poolTokenB={pairInfo[index].poolTokenB}/>
            ))}
      
      </Box>
    }

    </Box>

      <Flex
        alignItems="center"
        justifyContent="space-evenly">
        <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
        </Text>
      </Flex>

      <Flex bottom="0px" justify="space-between" alignItems="center" width="99vw" mb={(isAndroid) ? "0.7rem" : "0.2rem"}>
        
        <Flex mr="16px" w="90px">
        </Flex>

        <Flex alignItems="center">
          <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
          <Box width="85px" height="30px">
            <Image src={logo_cronos} fit="contain"/>
          </Box>
        </Flex>

        <Flex alignItems="center" w={(web3Provider) ? "" : "90px"} >
          {web3Provider && (
          <Flex alignItems="center">
          <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
          <CircleIcon boxSize={3} color="rgb(55, 160, 105)" mr="0px"/>
          </Flex>
          )}
          {!web3Provider && (
          <Flex alignItems="center">
          <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
          <CircleIcon boxSize={3} color="transparent" mr="0px"/>
          </Flex>
          )}
        </Flex>
      </Flex>
    
    </Box>
  );
  
}

export default Swap;
